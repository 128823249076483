import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Hero.css'; // Ensure CSS styling is correctly linked
import heroImage from '../assets/images/hero.png';

const Hero = () => {
  const navigate = useNavigate(); // React Router hook to navigate to different pages

  const handleBookingClick = () => {
    navigate('/booking'); // Navigate to the booking page
  };

  return (
    <section className="hero-section" aria-labelledby="hero-heading">
      <img 
        src={heroImage} 
        alt="A beautifully detailed car in showroom condition" 
        className="hero-background" 
        aria-hidden="true"
      />
      <div className="hero-overlay" aria-hidden="true"></div>
      <div className="hero-content">
        <h1 id="hero-heading">Experience the Ultimate Car Detailing Service</h1>
        <p>Discover premium detailing with fixed pricing—no surprises, just a showroom-quality finish every time.</p>
        <button 
          onClick={handleBookingClick} 
          className="cta-button" 
          aria-label="Book your car detailing appointment now"
        >
          Book Your Detailing Now
        </button>
      </div>
    </section>
  );
};

export default Hero;

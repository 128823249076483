// src/pages/BookingOverview.js
import React, { useEffect } from 'react';
import './BookingOverview.css';

const BookingOverview = () => {
  useEffect(() => {
    // Redirect instantly to the Square Appointments link
    window.location.href = 'https://squareup.com/appointments/book/m6h0tfokwma6jp/L61PV3HWF8GS5/start';
  }, []);

  return (
    <div role="main" aria-labelledby="booking-overview-title">
      <section className="booking-overview">
        <h1 id="booking-overview-title">Redirecting to Gloss Wise Booking</h1>
        <p>If you are not redirected, <a href="https://squareup.com/appointments/book/m6h0tfokwma6jp/L61PV3HWF8GS5/start">click here</a> to proceed manually.</p>
      </section>
    </div>
  );
};

export default BookingOverview;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ThankYou.css';

const ThankYou = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically redirect to the home page after 15 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 15000);

    // Cleanup timer if the component is unmounted before redirect
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="thank-you-page" role="main" aria-labelledby="thank-you-page-title">
      <div className="thank-you-content">
        <h1 id="thank-you-page-title">Thank You for Contacting Gloss Wise</h1>
        <p>We appreciate you reaching out. Our team will get back to you shortly!</p>
        <p>You will be redirected to the home page in 15 seconds.</p>
        <p>
          If you prefer to return now, click{' '}
          <button
            className="cta-button"
            onClick={() => navigate('/')}
            aria-label="Return to home page"
          >
            here
          </button>
        </p>
      </div>
    </div>
  );
};

export default ThankYou;

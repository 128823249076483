import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy" role="document" aria-labelledby="privacy-policy-title">
      <h1 id="privacy-policy-title">Privacy Policy</h1>
      <p>
        <strong>Gloss Wise</strong> is committed to protecting your personal information and respecting your privacy.
      </p>
      <h2 id="information-we-collect">1. Information We Collect</h2>
      <p>
        We collect information you provide directly to us, such as when you book our services, contact us, or create an account.
      </p>
      <ul aria-labelledby="information-we-collect">
        <li>Contact details, such as your name, email address, phone number, and address.</li>
        <li>Vehicle information, such as make, model, and condition.</li>
        <li>Any other information you choose to provide.</li>
      </ul>

      <h2 id="how-we-use-your-information">2. How We Use Your Information</h2>
      <p>
        We use your information to provide our car detailing services, process payments, and communicate with you regarding your booking or inquiries.
      </p>
      <ul aria-labelledby="how-we-use-your-information">
        <li>Provide, maintain, and improve our services.</li>
        <li>Process transactions and send you related information.</li>
        <li>Respond to your comments, questions, and requests.</li>
        <li>Communicate with you about products, services, offers, and events.</li>
        <li>Monitor and analyze trends, usage, and activities in connection with our services.</li>
      </ul>

      <h2 id="sharing-your-information">3. Sharing Your Information</h2>
      <p>
        We do not sell your personal information to third parties. We may share your information with service providers to assist in providing our services.
      </p>
      <ul aria-labelledby="sharing-your-information">
        <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</li>
        <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process.</li>
        <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Gloss Wise or others.</li>
        <li>With your consent or at your direction.</li>
      </ul>

      <h2 id="security">4. Security</h2>
      <p>
        We take reasonable measures to protect the information we collect from unauthorized access, disclosure, or misuse. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the security of your information.
      </p>

      <h2 id="your-rights">5. Your Rights</h2>
      <p>
        You have the right to access, update, or delete your personal information. Contact us if you need help with any of these actions.
      </p>
      <p>
        You may also have the right to object to or restrict certain types of processing of your personal information and to request a copy of the information we hold about you in a structured, machine-readable format.
      </p>

      <h2 id="changes-to-this-policy">6. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.
      </p>
      <p>
        If you have any questions about this Privacy Policy, please contact us at <strong>zackary@glosswise.com.au</strong>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;

// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client'; // Updated import
import App from './App';
import './index.css';

// Create the root
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <App />
);

import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <>
      <a href="#terms-content" className="skip-link">Skip to Content</a>
      <main id="terms-content" className="terms-and-conditions" role="main">
        <h1>Terms and Conditions</h1>
        <h2>1. Introduction</h2>
        <p>Welcome to <strong>Gloss Wise</strong>. By booking our car detailing services, you agree to be bound by the following terms and conditions. Please read them carefully.</p>

        <h2>2. Services Provided</h2>
        <p>Gloss Wise offers mobile and drop-off car detailing services in New South Wales, Australia. The services are described in detail on our website, and we strive to ensure the information is accurate and up-to-date.</p>

        <h2>3. Payments</h2>
        <p>Payments must be made in full before or upon completion of services. We accept cash, credit cards, and other methods as specified on our website. All prices include GST where applicable.</p>

        <h2>4. Liability</h2>
        <p>Gloss Wise will exercise all due care when performing car detailing services. However, we are not liable for pre-existing damages or wear and tear. Customers are responsible for ensuring all valuables are removed from their vehicles before the service.</p>

        <h2>5. Customer Obligations</h2>
        <p>Customers must provide accurate information when booking services and ensure access to water and power if opting for a mobile service. Customers should also ensure that their vehicles are available at the time and location agreed upon.</p>

        <h2>6. Changes to Terms</h2>
        <p>Gloss Wise reserves the right to update or modify these terms at any time. We will notify customers of any significant changes by updating the terms on our website.</p>

        <h2>7. Governing Law</h2>
        <p>These terms and conditions are governed by the laws of New South Wales, Australia. Any disputes arising from the use of our services will be subject to the jurisdiction of the courts in New South Wales.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions about these Terms and Conditions, please contact us at <strong>zackary@glosswise.com.au</strong>.</p>
      </main>
    </>
  );
};

export default TermsAndConditions;
